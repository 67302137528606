<template>
  <div>
    <!-- :submit-flag="submitFlag&&itemOk" -->
    <info-content :submit-flag="submitFlag && itemOk" brgb="244,245,252" use-bstyle :btn-remark="$t('baseInfo.safeTips')"
      :sub-text="$t('baseInfo.nextStep')" :smal-title="small" :need-gray="true" @submit="submitValidate"
      @graySubmit="graySubmitFn" @headerBack="headerBack" :headerImg="headerImg">
      <div class="data-form">
        <!-- validate-trigger="onSubmit" -->
        <van-form ref="dataForm" :show-error-message="false" validate-first>
          <div class="step-line">
            <span>{{ $t('baseInfo.title') }}</span>
            <span>{{ $t('baseInfo.cashWithdraw') }}</span>
            <span>{{ $t('baseInfo.identityInformation') }}</span>
          </div>
          <div class="module-title">{{ $t('baseInfo.title') }}</div>
          <!-- 邮箱 -->
          <van-field :disabled="emailStatus==='1'" v-model.trim="dataForm.email" name="email" @click="confirmClickNum('email','email_num')" :rules="rules.Email" class="form-item form-item-email"
            :placeholder="$t('baseInfo.email')" >
            <span :class="{'activate-grey':emailStatus==='1'}" @click="activateTheMailbox" slot="button" class="activate-email">{{emailStatus==='1'? $t('baseInfo.activated') : $t('baseInfo.activation')}}</span>
          </van-field>
       
          <!-- whatsapp  -->
          <van-field v-model.trim="dataForm.whatsapp" @click="confirmClickNum('whatsapp','whatsapp_num')"  :maxlength="maxLength" name="whatsApp"
            :rules="rules.WhatsApp" type="digit" class="form-item" :placeholder="$t('baseInfo.whatsappNumber')" />
          <!-- 性别(新增) -->
          <div class="sex-box">
            <p class="sex-label">{{ $t('cupones.qingxuanzenindeSex') }}</p>
            <van-radio-group v-model="dataForm.gender"  @click.native="handleSexClick"
              direction="horizontal" class="radio-box">
              <van-radio name="Male">
                <template #icon="props">
                  <span :class="{ 'is-check': props.checked }" class="check-icon" />
                </template>
                {{ $t('cupones.nan') }}
              </van-radio>
              <van-radio name="Female">
                <template #icon="props">
                  <span :class="{ 'is-check': props.checked }" class="check-icon" />
                </template>
                {{ $t('cupones.nv') }}
              </van-radio>
            </van-radio-group>
          </div>
          <!-- 年龄(新增) -->
          <van-field v-model.trim="dataForm.age" :maxlength="3" name="age" :rules="rules.age"
            type="digit" class="form-item" :placeholder="$t('cupones.qingshurunindeAge')" />
          <!-- 生日(新增) -->
          <div class="sex-box">
            <p class="sex-label">{{ $t('cupones.qingxuanzenindeBirthday') }}</p>
            <van-field v-model.trim="dataForm.birthday" readonly 
              :rules="[{ required: true, message: '' }]"
              @click="birthdayshow = true" name="birthday" type="text" class="form-item birthday-box"
              :placeholder="$t('cupones.qingxuanzenindeBirthday')">
              <template #right-icon>
                <img class="birthdayclass" src="@/assets/images/baseInfo/birthday-icon.png" alt='' />
              </template>
            </van-field>
            <van-popup class="popup-birthday" v-model="birthdayshow" round position="bottom" :style="{ height: '30%' }">
              <van-datetime-picker v-model="currentDate" type="date" :title="$t('cupones.qingxuanzenindeBirthday')"
                :columns-order="['year', 'month', 'day']" :min-date="minDate" :max-date="maxDate"
                @confirm="confirmBirthday" @cancel="birthdayshow = false" :confirm-button-text="$t('settings.confirmBtn')"
                :cancel-button-text="$t('settings.cancleBtn')" />
            </van-popup>

          </div>
          <!-- 证件类型 (新增)-->
          <!-- <div class="sex-box">
            <p class="sex-label">
              {{ $t('cupones.nindezhengjianType') }}
              <span class="card-type">{{ card_typeList[dataForm.card_type]?.name || '' }}</span>
            </p>

            <van-field v-model.trim="dataForm.card_number" :disabled="!dataForm.birthday"
              :maxlength="card_typeList[dataForm.card_type]?.maxLength || 40" name="age"
              :type="card_typeList[dataForm.certificate_type]?.textType || 'text'" class="form-item birthday-box"
              :placeholder="card_typeList[dataForm.card_type]?.placeholder || ''" :rules="[
                { required: true, message: $t('cupones.qingshiruzhengquedeIdCard') },
                // { validator: card_typeList[dataForm.card_type]?.validate||'', message: $t('cupones.qingshiruzhengquedeIdCard') }
              ]" onkeyup="this.value=this.value.toUpperCase()">
              <template #left-icon>
                <div class="left-text" v-if="dataForm.card_type == 1">CRN</div>
                <div class="left-text" v-if="dataForm.card_type == 9">PRN</div>
              </template>
            </van-field>
          </div> -->
          <!-- 学历 -->
          <popu-action-select v-model.trim="dataForm.education"  name="education"  @click="confirmClickNum('education','education_num')" class="click-num"
            placeholder="baseInfo.educationName" :data-list="$t('baseInfo.educationList')"
            :is-focus-point.sync="isFocusPoint" />
          <!-- 婚姻状况 -->
          <popu-action-select v-model.trim="dataForm.marry_status" name="marryStatus"  @click="confirmClickNum('marry_status','marry_status_num')" class="click-num"
            placeholder="baseInfo.marryStatus" :data-list="$t('baseInfo.marryStatusList')"
            :is-focus-point.sync="isFocusPoint" />
          <!-- 借款用途 -->
          <popu-action-select v-model.trim="dataForm.useof_funds"  name="useof_funds" @click="confirmClickNum('useof_funds','useof_funds_num')" class="click-num"
            placeholder="baseInfo.usageOfloan" :data-list="$t('baseInfo.usageOfloanList')"
            :is-focus-point.sync="isFocusPoint" />
          <!-- 地址 -->
          <popup-area ref="popupArea" v-model.trim="dataForm.residential_area"   @click="confirmClickNum('region','region_num')" class="click-num"
            :is-focus-point.sync="isFocusPoint" name="areaName" title="baseInfo.liveArea" @onFinish="areaFinish" />
          <!-- 详细地址 -->
          <van-field v-model="dataForm.dwell_address" name="liveAddress"  @click="confirmClickNum('dwell_address','dwell_address_num')"
            :rules="rules.LiveAddress" type="text" class="form-item click-num" :placeholder="$t('baseInfo.liveAreaDetail')" />

          <div class="module-title m12 open-show" @click="showContactInfo = !showContactInfo">{{
            $t('baseInfo.addContactInfo') }}</div>
          <collapse-transition>
            <div v-show="showContactInfo" ref="contactInfoBox" class="contactInfo-box">
              <div class="module-title">{{ $t('baseInfo.contactInfo') }}</div>
              <div v-for="(item, index) in dataForm.contact_json" :key="index">
                <div class="module-title-item">{{ $t('baseInfo.urgentPeople') }} {{ index + 1 }}</div>
                <popu-action-select v-model="item.relationship"  name-or-val="val"
                  val-or-name="val" :name="'relationship' + index" placeholder="baseInfo.contactRelationship"
                  @click="confirmClickNum('relationship'+getIndexText(index+1) ,'relationship'+getIndexText(index+1)+'_num')"
                  :data-list="relationshipList[index === 0 ? 0 : 1]" :is-focus-point.sync="isFocusPoint" />
                <van-field v-model="item.phone" :readonly="isHandInput+''!=='1'"  :rules="inputTelValid" 
                  :name="'relationshipPhone' + index" type="digit" class="form-item select-phone"
                  ref="selectPhone"
                  :placeholder="$t('baseInfo.telephone')" @click="openMyBook(index,'phone')" />
                <van-field v-model="item.name" :readonly="!dataForm.contact_json[index].phone.length" :name="'relationshipName' + index" type="text" class="form-item"
                @click="confirmClickNum('name'+getIndexText(index+1) ,'name'+getIndexText(index+1)+'_num',dataForm.contact_json[index].phone.length?'':index+1)"  
                :rules="[{ required: true, message: '' }]"
                @blur="blurName(index)"
                :placeholder="$t('baseInfo.contactName')"  />
              </div>
            </div>
          </collapse-transition>

          <div class="module-title m8 open-show" @click="boxshow = !boxshow">{{ $t('baseInfo.addWorkInfo') }}</div>
          <collapse-transition>
            <div v-show="boxshow">
              <div class="module-title-item">{{ $t('baseInfo.careerInfo') }}</div>
              <alone-tab ref="aloneTab" v-model="dataForm.work_type" val-or-name="val" @click="confirmClickNum('work_type','work_type_num')" class="click-num"
                :data-list="$t('baseInfo.careerInfoList')" />
                <keep-alive>
                  <component :is="componentId" ref="componentId" :psubmit-flag="submitFlag" @submitFlag="itemIsOk" />
                </keep-alive>
            </div>
          </collapse-transition>
        </van-form>
      </div>
    </info-content>
    <base-dialog v-if="dialogVisible" ref="baseDialog" show-cancel-button is-reverse :message="$t('baseInfo.receiveMsg')"
      :confirm-button-text="$t('public.confirmBtn')" :cancel-button-text="$t('public.cancleBtn')" @cancel="confirm" />

      <!-- 邮箱激活弹窗 -->
      <base-dialog class="activate-email-dialog" v-if="emailDialogVisible" ref="activateTheMailboxDialog" show-cancel-button :message="$t('baseInfo.activationEmailTips')"
      :confirm-button-text="$t('public.confirmBtn')" :cancel-button-text="$t('public.cancleBtn')" @confirm="getActivationEmailStatus(true)" />
  </div>
</template>

<script>
import i18n from '@/i18n'
import perfectInformation from '../perfectInformation'
import rules from '@/utils/rules'
import { validRelationTel } from '@/utils/validate'
import { onVisibilityChange } from '@/utils'

import InfoContent from '../components/infoContent.vue'
import PopupArea from '@/components/popupArea'
import BusinessOwner from './businessOwner/index.vue'
import OfficeWorker from './officeWorker/index.vue'
import Student from './student/index.vue'
import Unemployed from './unemployed/index.vue'
import AloneTab from './components/aloneTab.vue'
import CollapseTransition from '@/utils/collapse-transition'
import { activationEmail, getActivationEmailStatus } from '@/api/axios'

import { Toast } from 'vant'
export default {
  name: 'BaseInfo',
  components: {
    InfoContent,
    PopupArea,
    BusinessOwner,
    OfficeWorker,
    Student,
    Unemployed,
    AloneTab,
    CollapseTransition
  },
  mixins: [perfectInformation],
  data() {
    return {
      isHandInput:this.$andirodData.handInput,
      emailDialogVisible:false,
      minDate: new Date(1900, 0, 1),//(新增)
      maxDate: new Date(),//(新增)
      currentDate: new Date(),//(新增)
      birthdayshow: false,//生日弹窗(新增)
      callLogsIsAuth:false, // 基本信息权限
      //证件类型  maxLength 比正常多出6个字符 (新增)
      card_typeList: {
        1: {
          maxLength: '16',
          type: 1,
          name: 'UMID',
          textType: "digit",
          placeholder: this.$t('cupones.number12'),
          validate: (val) => {
            // const Reg = /^([C]{1})([R]{1})([N]{1})([0-9]{12})$/
            const Reg = /^([0-9]{12})$/
            const isRight = Reg.test(val)
            if (!isRight) {
              this.$toast.fail(this.$t('cupones.qingshiruzhengquedeIdCard'))
            }
            return isRight
          }//CRN开头+12位数字
        },
        2: {
          maxLength: '16',
          type: 2,
          name: 'SSS',
          textType: "digit",
          placeholder: this.$t('cupones.number10'),
          validate: (val) => {
            const Reg = /^\d{10}$/
            const isRight = Reg.test(val)
            if (!isRight) {
              this.$toast.fail(this.$t('cupones.qingshiruzhengquedeIdCard'))
            }
            return isRight
          }//纯数字，共10位
        },
        3: {
          maxLength: '16',
          type: 3,
          name: 'TIN ID',
          textType: "digit",
          placeholder: this.$t('cupones.nubmer9_12'),
          validate: (val) => {
            const Reg = /^\d{9,12}$/
            const isRight = Reg.test(val)
            if (!isRight) {
              this.$toast.fail(this.$t('cupones.qingshiruzhengquedeIdCard'))
            }
            return isRight
          }//纯数字，9-12位
        },
        4: {
          maxLength: '15',
          type: 4,
          name: 'PASSPORT',
          textType: "text",
          placeholder: this.$t('cupones.stringNumber9'),
          validate: (val) => {
            const Reg = /^[a-zA-Z][a-zA-Z0-9]{8}$/
            const isRight = Reg.test(val)
            if (!isRight) {
              this.$toast.fail(this.$t('cupones.qingshiruzhengquedeIdCard'))
            }
            return isRight
          }//字母开头，共9位
        },
        5: {
          maxLength: '17',
          type: 5,
          name: 'Driver’s license',
          textType: "text",
          placeholder: this.$t('cupones.stringNumber11'),
          validate: (val) => {
            const Reg = /^[a-zA-Z]\d{10}$/
            const isRight = Reg.test(val)
            if (!isRight) {
              this.$toast.fail(this.$t('cupones.qingshiruzhengquedeIdCard'))
            }
            return isRight
          }//开头字母+共11位
        },
        6: {
          maxLength: '40',
          type: 6,
          name: 'National ID',
          textType: "text",
          placeholder: this.$t('cupones.qingshuruIdcard'),
          validate: ''
        },
        7: {
          maxLength: '13',
          type: 7,
          name: 'PRC ID',
          textType: "digit",
          placeholder: this.$t('cupones.number7'),
          validate: (val) => {
            const Reg = /^\d{7}$/
            const isRight = Reg.test(val)
            if (!isRight) {
              this.$toast.fail(this.$t('cupones.qingshiruzhengquedeIdCard'))
            }
            return isRight
          }//纯数字 7位
        },
        8: {
          maxLength: '40',
          type: 8,
          name: 'Pagibig',
          textType: "text",
          placeholder: this.$t('cupones.qingshuruIdcard'),
          validate: ''
        },
        9: {
          maxLength: '19',
          type: 9,
          name: 'Postal ID',
          textType: "text",
          placeholder: this.$t('cupones.PRN13stringNumber'),
          validate: (val) => {
            // const Reg = /^([P]{1})([R]{1})([N]{1})([0-9]{12})$/
            const Reg = /^([a-zA-Z0-9]{13})$/
            const isRight = Reg.test(val)
            if (!isRight) {
              this.$toast.fail(this.$t('cupones.qingshiruzhengquedeIdCard'))
            }
            return isRight
          }//PRN开头+13位数字
        },
        10: {
          maxLength: '30',
          type: 10,
          name: "Voter's ID",
          textType: "text",
          placeholder: this.$t('cupones.stringNumber22_23'),
          validate: (val) => {
            const Reg = /^([a-zA-Z0-9]{22,23})$/
            const isRight = Reg.test(val)
            if (!isRight) {
              this.$toast.fail(this.$t('cupones.qingshiruzhengquedeIdCard'))
            }
            return isRight
          }//数字和字母22-23位
        },
        11: {
          maxLength: '18',
          type: 11,
          name: 'PhilHealth ID',
          textType: "digit",
          placeholder: this.$t('cupones.number12'),
          validate: (val) => {
            const Reg = /^\d{12}$/
            const isRight = Reg.test(val)
            if (!isRight) {
              this.$toast.fail(this.$t('cupones.qingshiruzhengquedeIdCard'))
            }
            return isRight
          }//纯数字 12位
        },
      },
      headerImg: require('@/assets/images/baseInfo/baseinfo-top-icon.png'),
      next_num: 0,
      rules,
      dialogVisible: false,
      componentId: '',
      itemOk: false,
      workInfo: {},
      showContactInfo: true, // 展开联系人
      boxshow: true, // 展开职业
      relationshipList: this.$t('baseInfo.alternateContactList'), // 联系人关系
      emailStatus:'',
      dataForm: {
        gender: 'Male',//性别(新增)
        age: '',//年龄(新增)
        birthday: '',//生日(新增)
        card_type: '0',//证件类型(新增)
        // card_number: '',//卡号(新增)
        email: '', // 邮箱
        education: '', // 学历
        whatsapp: '', // whatsapp 号
        marry_status: '', // 婚姻状况
        useof_funds: '', // 借款用途
        dwell_provinces: '', // 居住省份
        dwell_city: '', // 居住县
        dwell_county: '', // 居住区
        dwell_address: '', // 详细地址
        work_type: '1', // 职业类型 1.上班族 2.经营者 3.学生 4.无业
        ktp_status: '1', // 后台让写死先传1
        submit_tag: '1', // 提交标识（1 正常提交 2 返回提交）
        residential_area: '',
        contact_json: [
          {
            'name': '', // 最后的名字
            'up_name':'', // 第一次选择的名字
            'relationship': '',
            'phone': ''
          },
          {
            'name': '',
            'up_name':'', // 第一次选择的名字
            'relationship': '',
            'phone': ''
          },
          {
            'name': '',
            'up_name':'', // 第一次选择的名字
            'relationship': '',
            'phone': ''
          }
        ] // 紧急联系人的集合
      },
      inputTelValid: [
        { required: true, message: '' },
        { validator: this.inputTelValidValidator}
      ]
    }
  },
  computed: {

    maxLength() {
      if (this.dataForm.whatsapp.indexOf('09') === 0) {
        return 11
      } else if (this.dataForm.whatsapp.indexOf('63') === 0) {
        return 12
      } else {
        return 10
      }
    },
    small() {
      return i18n.locale !== 'en' ? 'small' : ''
    },
    contactInfoDisabled() {
      return function (index) {
        if (index + '' === '0') {
          return !this.dataForm.dwell_address
        } else {
          return !this.dataForm.contact_json[index - 1].name
        }
      }
    }
  },
  watch: {
    'dataForm.work_type': {
      handler() {
        this.componentId = this.getTypeDic(this.dataForm.work_type)
        console.log('这是啥啊', this.dataForm.work_type)
        this.$store.state.settings.work_type = this.dataForm.work_type
        // this.itemOk = false
        this.$nextTick(()=>{
          this.itemOk = this.$refs.componentId.submitFlag
        })
        
        
      },
      immediate: true,
      deep: true
    },
  },

  created() {
    this.getBasicInfo()
    this.$H5Object.changeStatusBarBgColor('#F4F5FC')
    this.$H5Object.changeStatusIsDarkFont(true)
    // this.$H5Object.isShowBottomTabinter(false)
    window.eaH5PageBack = this.headerBack
  },

  methods: {
    // (新增)
    confirmBirthday(val) {
      console.log(val);
      let year = val.getFullYear()
      let month = val.getMonth() + 1
      let day = val.getDate()
      let hour = val.getHours()
      let minute = val.getMinutes()
      if (month >= 1 && month <= 9) { month = `0${month}` }
      if (day >= 1 && day <= 9) { day = `0${day}` }
      if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
      if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
      this.dataForm.birthday = `${year}-${month}-${day}`
      this.birthdayshow = false
    },
    //  返回操作
    headerBack() {
      this.showDialog()
    },

    // 获取基本信息权限
    baseInfoGetAuth(){
      const _t = this
      window.callLogsFinish = ()=>{
        _t.callLogsIsAuth = true
        _t.setContactSun(1)
      }
      this.$H5Object.baseInfoGetAuth()
    },

    // 验证提交
    validateForm(callback) {
      const baseInfoArry = ['email','whatsApp','age','birthday','education','marryStatus','useof_funds','areaName','liveAddress','relationship0','relationship1','relationship2','relationshipPhone0','relationshipPhone1','relationshipPhone2','relationshipName0','relationshipName1','relationshipName2']
      this.$refs.dataForm.validate(baseInfoArry).then(() => {
        callback && callback(true)
      }).catch(faildList => {
      
        // 聚焦到格式错误的输入框
        let failName = faildList[0].name
        let inputItem = document.getElementsByName(failName)[0]
        inputItem.focus()
  
        callback && callback(false)
      })
    },

    // 提交验证
    submitValidate() {
      this.validateForm(valid => {
        if (!valid) return
        this.next_num += 1
        this.setContactSun(1)
      })
    },

    // 灰色按钮提交验证
    graySubmitFn() {
      // 聚焦到未填写的输入框
      let inputList = document.getElementsByTagName('input')
      for (let i = 0; i < inputList.length; i++) {
        if (!inputList[i].value || inputList[i].value == '0') {
          console.log('验证的表单',inputList[i])
          // inputList[i].focus()
          if(inputList[i].name){
            this.$refs.dataForm.validate(inputList[i].name).then().catch(faildList => {
              // 聚焦到格式错误的输入框
              let failName = faildList.name
              let inputItem = document.getElementsByName(failName)[0]
              inputItem.focus()
            })
          }
          return
        }
      }
    },

    getTypeDic(key) {
      const kv = {
        '1': 'OfficeWorker',
        '2': 'BusinessOwner',
        '3': 'Student',
        '4': 'Unemployed'
      }
      return kv[key]
    },

    // 备用联系人校验
    checkContact(selectPhone,inputPhone,isSelect) {
      const phone = this.$andirodData.phone_number || this.$andirodData.mobile || localStorage.getItem('mobile') || ''
      console.log(phone+'本机号码')
      let relationTel = [this.dataForm.contact_json[0].phone+'', this.dataForm.contact_json[1].phone+'', this.dataForm.contact_json[2].phone+'']
      let relationTel2 = [this.dataForm.contact_json[0].phone+'', this.dataForm.contact_json[1].phone+'', this.dataForm.contact_json[2].phone+'']
      relationTel = Array.from(new Set(relationTel))
      let flag = true
      selectPhone = selectPhone.replace(/^0/g, '')
      Toast.allowMultiple(true)
      if ((relationTel.includes(selectPhone)|| relationTel.includes(('0'+selectPhone))) && flag && this.isHandInput+''!=='1' && isSelect==='selectPhone') {
        console.log('选择相同')
        this.$toast({
          type: 'fail',
          message: this.$t('rules.asIphoneError'),
          className: 'toast-width',
          duration: 3000
        })
        flag = false
        return
      }
      
      if(this.isHandInput+'' === '1'){
        console.log('选择相同')
        let count = 0;
          for (let i = 0; i < relationTel2.length; i++) {
            if (relationTel2[i]+'' === inputPhone+'' || relationTel2[i]+'' === '0'+inputPhone) {
              count++;
            }
          }
          console.log(count)
          if(count>1){
            this.$toast({
              type: 'fail',
              message: this.$t('rules.asIphoneError'),
              className: 'toast-width',
              duration: 3000
            })
            flag = false
            return
          }
          
      }
     
      if ((selectPhone + '' === phone + '' || '0'+selectPhone === phone + '' || selectPhone+'' === '0'+phone) && flag) {
        this.$toast({
          type: 'fail',
          message: this.$t('rules.asLoclIphoneError'),
          className: 'toast-width',
          duration: 3000
        })
        flag = false
        return
      }
      return flag
    },
    // 通讯录调用
    openMyBook(index,str) {
      if(this.isHandInput+''==='1')return
      this.confirmClickNum(str+this.getIndexText(index+1) ,str+this.getIndexText(index+1)+'_num')
      Toast.clear()
      const _t = this
      this.$H5Object.unfoldContact()
      this.isFocusPoint = true
      window.getContactPerson = (data) => {
        _t.isFocusPoint = false
        if (!data) return
        const whatsappnum1 = /^63[0-9]{7,11}$/
        const whatsappNumbernum1two = /^\+63/
        let dataHandle = '{}'
        if (typeof data === 'string') {
          dataHandle = JSON.parse((dataHandle))
        } else {
          dataHandle = data
        }
        if (whatsappNumbernum1two.test(dataHandle?.phone_name)) {
          dataHandle.phone_name = dataHandle.phone_name.replace(/^\+63/g, '0')
        }
        if (whatsappnum1.test(dataHandle.phone_name)) {
          dataHandle.phone_name = dataHandle.phone_name.replace(/^63/g, '0')
        }
        if (dataHandle.phone_name.indexOf('+63')) {
          dataHandle.phone_name = dataHandle.phone_name.replace(/^\+63/g, '0')
        }
        if (dataHandle.phone_name.indexOf('63')) {
          dataHandle.phone_name = dataHandle.phone_name.replace(/^63/g, '0')
        }
        if (!validRelationTel(dataHandle.phone_name)) return
        // 手机号 输入的手机号 是否选择
        if (!_t.checkContact(dataHandle.phone_name+'','','selectPhone')) return
        _t.dataForm.contact_json[index].name = dataHandle?.name || ''
        _t.dataForm.contact_json[index].up_name = dataHandle?.name || ''
        _t.dataForm.contact_json[index].phone = dataHandle?.phone_name || ''
        _t.$refs.selectPhone[index-1].$el.classList.remove('empty-phone')
        console.log(dataHandle, 'dataHandle')
      }
    },

    itemIsOk(isOk) {
      console.log(isOk, 'isOkisOk--');
      this.itemOk = isOk
    },

    // 展示返回弹窗
    showDialog() {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.baseDialog.init()
      })
    },

    async confirm() {
      this.dataForm.submit_tag = 2
      this.setContactSun(2)
      // await this.dataFromSubmit()
    },

    // 后台切换
    onVisibilityChange() {
      const _t = this
      onVisibilityChange(function (val) {
        if (!val) {
          _t.dataForm.submit_tag = 2
          // _t.setContactSun(2)
        }else{
          if(_t.emailStatus==='1')return
          _t.getActivationEmailStatus() // 获取一下邮箱激活状态
        }
      })
    },

    // 设置总时长 后台小伙伴不愿意给改字段
    setContactSun(type) {
      this.dataForm.submit_tag = type  //1跳转 2关闭 3打开通讯录暂存
      const version = (this.$andirodData.Ubwafwilisho_Application+'').replace(/\./g,'')*1
      if(!this.callLogsIsAuth && type+'' === '1' && version >= 1015){
        this.baseInfoGetAuth()
        return
      }
      this.dataFromSubmit()
    },

    // 激活邮箱打开弹窗
    activateTheMailbox(){
      const _t = this
      this.$refs.dataForm.validate(['email']).then(() => {
        this.getActivationEmailStatus(true,function(ststus){
          if(ststus === '1')return
          _t.activationEmail()
        })
      }).catch(faildList => {
        // 聚焦到格式错误的输入框
        let failName = faildList[0].name
        let inputItem = document.getElementsByName(failName)[0]
        inputItem.focus()
      })
    },

    // 激活邮箱发送邮件
    activationEmail(){
      activationEmail({Umufikila_inkalata:this.dataForm.email}).then(()=>{
        this.emailDialogVisible = true
        this.$nextTick(() => {
          this.$refs.activateTheMailboxDialog.init()
        })
      })
    },

    // 获取邮箱激活状态
    getActivationEmailStatus(tips,callback){
      if(!this.dataForm.email || this.emailStatus==='1')return
      getActivationEmailStatus({Umufikila_inkalata:this.dataForm.email}).then(({data})=>{
        this.emailStatus = data.status+''  // 0未激活 1激活
        if(tips&&this.emailStatus==='1'){
          const _t = this
          setTimeout(() => {
            _t.$toast.success(_t.$t('baseInfo.successfulActivation'))
          }, 500);
        }
        callback&&callback(this.emailStatus)
      })
    },

    // 输入的时候校验手机号
    inputTelValidValidator(val){
      const whatsappnum1 = /^63[0-9]{7,11}$/
        const whatsappNumbernum1two = /^\+63/
      let inputPhone = JSON.parse(JSON.stringify(val))
        if (whatsappNumbernum1two.test(inputPhone)) {
          inputPhone = inputPhone.replace(/^\+63/g, '0')
        }
        if (whatsappnum1.test(inputPhone)) {
          inputPhone = inputPhone.replace(/^63/g, '0')
        }
        if (inputPhone.indexOf('+63')) {
          inputPhone = inputPhone.replace(/^\+63/g, '0')
        }
        if (inputPhone.indexOf('63')) {
          inputPhone = inputPhone.replace(/^63/g, '0')
        }
        console.log(inputPhone)
        if (!validRelationTel(inputPhone)) return false
        if (!this.checkContact(inputPhone+'',val+'')) return false
    },

    // 姓名输入的时候
    blurName(index){
      if(this.isHandInput+''!=='1')return
      if(!this.dataForm.contact_json[index].up_name){
        this.dataForm.contact_json[index].up_name = this.dataForm.contact_json[index].name
      }
    }

  }
}
</script>
<style lang="scss" scoped>
/deep/ .van-field__right-icon {
  right: 32px;
  position: absolute;

  .birthdayclass {
    width: 44px;
    height: 44px
  }
}

/deep/ .left-text {
  padding-left: 24px;
  height: 100px;
  line-height: 100px;
  font-size: 28px;
}

.popup-birthday {
  padding: 32px 24px;

  /deep/.van-picker__toolbar {
    height: auto;
    margin-bottom: 20px;

    .van-picker__title,
    .van-picker__cancel,
    .van-picker__confirm {
      line-height: normal;
      font-size: 28px !important;
    }

    .van-picker-column {
      font-size: 26px !important;
    }

  }
}

/deep/ .van-ellipsis {
  font-size: 26px !important;
}

.birthday-box {
  background: #FFF !important;
  margin-bottom: 0px !important;
  margin-top: 24px;
}

.sex-box {
  padding: 26px 32px;
  border-radius: 16px;
  background: #F7F7F7;
  margin-bottom: 24px;

  .sex-label {
    font-size: 26px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #333333;
    line-height: 48px;

    .card-type {
      margin-left: 16px;
      font-size: 26px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #005FE9;
    }
  }

  .radio-box {
    padding: 0 50px;
    margin-top: 40px;
    justify-content: space-between;
  }

  .check-icon {
    display: block;
    width: 32px;
    height: 32px;
    background: url('~@/assets/images/coupon/no-select.png') no-repeat center center;
    background-size: 100%
  }

  .is-check {
    background: url('~@/assets/images/coupon/select.png') no-repeat center center;
    background-size: 100%
  }
}

:deep(.van-dropdown-item__option--active) {
  .van-cell__title {
    color: #005FE9 !important;
  }

}

:deep(.is-content) {
  padding: 0;
  padding-bottom: 40px;
}

:deep(.headerbase) {
  background: rgba(244, 245, 252, 0);
}

.data-form {
  // position: relative;
  // margin-top: 200px;
  // height: 100vh;
  // overflow-y: auto;

}

:deep(.van-form) {
  background: #FFFFFF;
  border-radius: 48px 48px 0 0;
  padding: 80px 40px 40px 40px;
  position: relative;
}

.module-title {
  font-size: 32px;
  font-weight: bold;
  color: #000000;
  line-height: 48px;
  margin-bottom: 24px;
}

.module-title-item {
  height: 40px;
  font-size: 26px;
  color: #1C1D21;
  line-height: 40px;
  margin-bottom: 24px;
}

:deep(.van-radio__icon) {
  height: auto;
}

:deep(.has-val .van-field__control:disabled) {
  -webkit-text-fill-color: initial;
}

:deep(.van-popover__wrapper) {
  display: block;
}

:deep(.van-popover) {
  left: 32px !important;
  right: 32px !important;
}

:deep(.van-popover__content) {
  background: none;
  box-shadow: none;
}

.contact-remark {
  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #999999;
  line-height: 32px;
}

.ptop46 {
  padding-top: 46px;
}

:deep(.van-field__error-message) {
  position: absolute;
  z-index: 10;
  font-size: 26px;
  bottom: -40px;
}

:deep(.form-item) {
  overflow: initial;
}

:deep(.uppercase input) {
  text-transform: uppercase;
}

.select-phone {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 44px;
    top: 0;
    bottom: 0;
    right: 26px;
    z-index: 3;
    background: url('~@/assets/images/baseInfo/concat-icon.png') no-repeat center center;
    background-size: 100%;
  }
}

:deep(.select-phone .van-field__value) {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 28px;
    top: 0;
    bottom: 0;
    right: 30px;
    background: url('~@/assets/images/baseInfo/select-icon.png') no-repeat center center;
    background-size: 100%;
  }
}

.contact-info-remark {
  font-size: 24px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5000);
  line-height: 36px;
  margin-bottom: 20px;
  margin-top: -10px;
}

:deep(.work-type) {
  .list-item {
    height: 132px;
    line-height: 132px;
    background: #F7FAFF;
    border-radius: 12px;
    border: 1px solid #EEEEEE;
    margin-bottom: 30px;
    position: relative;
    text-align: right;
    padding-right: 110px;

    &::after {
      content: '';
      top: initial !important;
      position: absolute;
      left: 38px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 42px;
      height: 40px;
      right: 40px;
      top: 46px;
      background: url('~@/assets/images/baseInfo/no-checked-icon.png') no-repeat center center;
      background-size: 100%;
    }
  }

  .list-item.is-active {
    &::before {
      background: url('~@/assets/images/baseInfo/checked-icon.png') no-repeat center center;
      background-size: 100%;
    }
  }

  .van-radio:nth-child(1) .list-item {
    &::after {
      width: 144px;
      height: 148px;
      bottom: -25px;
      background: url('~@/assets/images/baseInfo/business-owner-icon.png') no-repeat center center;
      background-size: 100%;
    }
  }

  .van-radio:nth-child(2) .list-item {
    &::after {
      width: 146px;
      height: 148px;
      bottom: -25px;
      background: url('~@/assets/images/baseInfo/office-worker-icon.png') no-repeat center center;
      background-size: 100%;
    }

  }

  .van-radio:nth-child(3) .list-item {
    &::after {
      width: 146px;
      height: 156px;
      bottom: -35px;
      background: url('~@/assets/images/baseInfo/student-icon.png') no-repeat center center;
      background-size: 100%;
    }
  }

  .van-radio:nth-child(4) .list-item {
    &::after {
      width: 146px;
      height: 148px;
      bottom: -30px;
      background: url('~@/assets/images/baseInfo/unemployed-icon.png') no-repeat center center;
      background-size: 100%;
    }
  }
}

.step-line {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  // display: flex;
  margin-bottom: 80px;

  span {
    padding: 0 28px;
    // display: inline-block;
    // width: 30%;
    text-align: center;
    font-size: 24px;
    color: #005FE9;
    line-height: 36px;
    border-bottom: 6px solid #005FE9;
    padding-bottom: 16px;
    margin-right: 12px;
  }
}

.step-line span:nth-child(1) {
  letter-spacing: -0.28px;
}

.step-line span:nth-child(2) {
  margin-left: 12px;
  color: #B3B3B3;
  border-bottom: 6px solid #E6E6E6FF;
}

.step-line span:nth-child(3) {
  margin-left: 12px;
  margin-right: 0;
  color: #B3B3B3;
  border-bottom: 6px solid #E6E6E6FF;
}

:deep(.btn-box) {
  padding: 0 40px;
}

:deep(.item-title) {
  display: none;
}

.m8 {
  margin-bottom: 8px;
}

.m12 {
  margin-bottom: 12px;
}

:deep(.btn-box .remark) {
  margin-top: 24px;
}

:deep(.van-dialog) {
  width: auto;
  left: 40px;
  right: 40px;
  transform: translate3d(0%, -50%, 0);

  .van-dialog__content {
    padding: 48px 64px 50px 64px;
    line-height: 40px;
    text-align: left;
  }

  .van-dialog__footer {
    display: block;
    padding-bottom: 100px;

    .van-dialog__cancel {
      bottom: 0px;
      position: absolute;
      font-size: 36px;
      font-weight: 500;
      color: #1C1D21;
    }

    .van-dialog__confirm {
      height: 128px;
      padding-left: 64px;
      padding-right: 64px;
      color: #fff;
      font-size: 36px;
      font-weight: 500;

      .van-button__content {
        height: 128px;
        background: #1C1D21;
        border-radius: 32px;
      }
    }
  }
}

:deep([class*=van-hairline]::after) {
  display: none;
}

:deep(.child-title) {
  font-size: 37px;
}

:deep(.activate-email-dialog .van-dialog__content){
    text-align: center;
}

:deep(.child-title.small) {
  font-size: 24px;
  font-weight: bold;
}

.open-show {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 40px;
    top: 0;
    right: 10px;
    bottom: 0;
    background: url('~@/assets/images/baseInfo/open-arrow-icon.png') no-repeat center center;
    background-size: 100%;
  }
}
.empty-phone{
  border: 1px solid red;
}
.activate-email{
  // position: absolute;
  right: 0;
  font-size: 26px;
  color: #005FE9;
  top: 0;
  bottom: 0;
  padding-right: 24px;
  display: flex;
  flex: 1;
  align-items: center;
}

.activate-grey.activate-email{
  color: #999999;
}
</style>
