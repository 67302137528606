<template>
  <div>
    <div class="item-title">{{ title }}</div>
    <div class="item-choose-cont">
      <van-row>
        <van-radio-group v-model="emitVal" @change="handlechange">
          <van-radio v-for="(item,index) in dataList" :key="index" :disabled="item[disabledKey]+''===disabledVal+''" :name="item[valOrName]" @click="handleClick(item)">
            <template #icon="props">
              <van-col :class="{'m0':!((index+1)%m0Num),'is-active':props.checked}" class="choose-item" :span="span">
                {{ item[valKey] }}
              </van-col>
            </template>
          </van-radio>
        </van-radio-group>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    valOrName: {
      type: String,
      default: 'name'
    },
    title: {
      type: String,
      default: ''
    },
    span: {
      type: [String, Number],
      default: '12'
    },
    m0Num: {
      type: Number,
      default: 2
    },
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: {
      type: [String, Number],
      default: ''
    },
    valKey: {
      type: String,
      default: 'name'
    },
    disabledKey: {
      type: String,
      default: ''
    },
    disabledVal: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      emitVal: ''
    }
  },
  watch: {
    value: {
      handler() {
        this.emitVal = JSON.parse(JSON.stringify(this.value))
      },
      immediate: true
    }
  },
  methods: {
    handlechange(item) {
      this.$emit('input', this.emitVal)
    },

    handleClick(item) {
      this.$emit('click', this.emitVal, item)
    }
  }
}
</script>
<style lang="scss" scoped>
.item-title{
  font-size: 32px;
  padding: 46px 0;
  font-family: Roboto-Bold, Roboto;
  font-weight: bold;
  color: #002424;
}
:deep(.van-radio){
  display: initial;
}
:deep(.van-radio__icon){
  height: auto;
}
.choose-item{
  width: 323px;
  height: 100px;
  line-height: 100px;
  margin-right: 24px;
  text-align: center;
  margin-bottom: 24px;
  font-size: 26px;
  font-weight: bold;
  border-radius: 16px;
  background: #F7F7F7;
  color: #CCCCCC;
  // border: 2px solid #1761C5;
}
.is-active{
  color: #fff!important;
  background: #005FE9FF;
  // background: url(../../../../assets/images/baseInfo/is-active.png) no-repeat center center!important;
  // background-size: 100%;
}
.item-choose-cont{
  padding-bottom: 16px;
}
.m0{
  margin-right: 0!important;
}
</style>
